/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import ProductListInfoDispatcher from 'Store/ProductListInfo/ProductListInfo.dispatcher';
import { RootState } from 'Util/Store/Store.type';

import {
    ProductListContainerMapDispatchProps,
    ProductListContainerMapStateProps,
    ProductListContainerProps,
} from 'SourceComponent/ProductList/ProductList.type';
import {
    ProductListContainer as SourceProductListContainer
} from "SourceComponent/ProductList/ProductList.container";

/** @namespace Component/ProductList/Container/mapStateToProps */
export const mapStateToProps = (state: RootState): ProductListContainerMapStateProps => ({
    device: state.ConfigReducer.device,
});

/** @namespace Component/ProductList/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch: Dispatch): ProductListContainerMapDispatchProps => ({
    //@ts-ignore
    requestProductListInfo: (options) => ProductListInfoDispatcher.handleData(dispatch, options),
});

export class ProductListContainer extends SourceProductListContainer {

    customPrevFilter = {};

    componentDidUpdate(prevProps: ProductListContainerProps): void {
        const {
            sort,
            search,
            filter,
            pages,
            device,
            isPlp,
        } = this.props;

        const {
            sort: prevSort,
            sort: prevFilter,
            search: prevSearch,
            location: prevLocation,
        } = prevProps;

        const { pagesCount } = this.state;
        const pagesLength = Object.keys(pages).length;

        if (pagesCount !== pagesLength) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ pagesCount: pagesLength });
        }

        const prevPage = this._getPageFromUrl(prevLocation);
        const currentPage = this._getPageFromUrl();
        let isProductListUpdated = false;
        if (!Object.keys(this.customPrevFilter)) {
            isProductListUpdated = JSON.stringify(filter) !== JSON.stringify(prevFilter)
                || currentPage !== prevPage;
        } else {
            isProductListUpdated = JSON.stringify(filter) !== JSON.stringify(this.customPrevFilter)
                || currentPage !== prevPage;
        }

        this.customPrevFilter = JSON.parse(JSON.stringify(filter));
        const isSortUpdated = JSON.stringify(sort) !== JSON.stringify(prevSort);

        if (isProductListUpdated || isSortUpdated) {
            window.isPrefetchValueUsed = false;
        }

        // prevents requestPage() fired twice on Mobile PLP with enabled infinite scroll
        if (device.isMobile && this._getIsInfiniteLoaderEnabled() && isPlp && !(isProductListUpdated || isSortUpdated)) {
            return;
        }

        if (search !== prevSearch || isProductListUpdated) {
            this.requestPage(this._getPageFromUrl());
        }

        if (isSortUpdated) {
            const isOnlySortUpdated = true;
            this.requestPage(this._getPageFromUrl(), false, isOnlySortUpdated);
        }
    }
}

export default withRouter(
    // @ts-ignore
    connect(mapStateToProps, mapDispatchToProps)(
        ProductListContainer as unknown as React.ComponentType<
            RouteComponentProps & ProductListContainerProps
        >,
    ),
);
